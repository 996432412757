$single-link-color: #802000;
$single-link-color-dark: #cc0000;

$single-link-hover-color: #cc0000;
$single-link-hover-color-dark: #ff0000;

$global-link-hover-color: #802000;
$global-link-hover-color-dark: #cc0000;

$blockquote-color: #cc0000;
$blockquote-color-dark: #cc0000;

$global-border-color: #cc0000;
$global-border-color-dark: #cc0000 !important;

// All this CSS is for formatting posts without titles so they stand out
// vs the posts with titles.

.titleless-post {
	p {
		// need the !important here otherwise it isn't loaded.
		font-size: 1.25rem !important;
	}
	// This removes the blank like that the theme adds after paragraphs
	// which was screwing up the overflow stuff.
	// need the !important here otherwise it isn't loaded.
	p::after {
	  content: none !important;
	}
	color: #5e5e5e;
	[theme=dark] & {
    	color: #9999a2;
  	}
  	// Need to specify the bold colour, otherwise the default is used.
  	strong {
  		color: #5e5e5e !important;
  		[theme=dark] & {
			color: #9999a2 !important;
		}
  	}
  	// If links are in the post, these are needed to colour them correctly.
  	a {
  		[theme=dark] & {
  			color: #cc0000 !important;
  		}
  	}
  	a:hover {
  		[theme=dark] & {
  			color: #ff0000 !important;
  		}
  	}
}

// This is to format the embedded mastodon posts
.toot-author-name, .toot-author-handle {
	padding-left: 5px !important;
}
.toot-card {
	border:thin solid;
	background-color: rgba($blockquote-color, 0.2);
// 	border-radius:7px;
}
// .toot-card-image {
// 	border-radius:8px 8px 0px 0px
// }
.toot-card-content {
	padding-left:20px; 
	padding-right:20px;
}
.toot-footer {
	padding-top:5px;
}